import React from 'react'
import { useCookies } from 'react-cookie'

import { ROLES } from 'types/user'
import { PublicUser } from 'services/apiTypes/user.types'

import UserService from 'services/user.service'
import ToastService from 'services/toast.service'

import Icon from 'components/elements/Icon'
import Editor from 'components/formElements/richText/Editor'
import FollowButton from 'components/user/FollowButton'
import AvatarCircle from 'components/user/AvatarCircle'
import Link from 'next/link'
import PhatButton from 'components/formElements/PhatButton'

import { timeSince } from 'utils/time.utils'
import { encodeUsernameRoute } from 'utils/encoding'

import { emptyTextArea } from 'types/editor'

import styles from './userInfoAndBioCard.module.scss'

type Props = { user: PublicUser; className?: string }

const UserInfoAndBioCard = ({ user, className = '' }: Props) => {
  const [{ tbR: roles = [] }] = useCookies(['tbR'])

  const isModerator = roles.includes(ROLES.MOD)

  const handleLockUser = () => {
    // prettier-ignore
    const message = `Are you sure you want to lock user '${user.username}'? Doing so will prevent them from doing anything on the site until unlocked, but will not delete any of their existing data.`

    if (!window.confirm(message)) return

    UserService.lock(user.id)
      .then(() => ToastService.create('User has been locked', 'User Service', 'success'))
      .catch(() => ToastService.create('Unable to lock user at this time', 'User Service'))
  }

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.info}>
        <div className={styles.user}>
          <AvatarCircle className={styles.avatar} src={user.profile.avatar} username={user.username} />
          <Link href={`/u/${encodeUsernameRoute(user.username)}`}>
            <Icon name="user" />
            {user.username}
          </Link>
          <div className={styles.extras}>
            <FollowButton followId={user.id} followed={user.following} />
            {user.publicCollection && (
              <Link href={`/collection/v2/${user.id}`}>
                <PhatButton icon="address book outline">View card collection</PhatButton>
              </Link>
            )}
            {isModerator && (
              <PhatButton onClick={handleLockUser} icon="lock">
                Lock User
              </PhatButton>
            )}
          </div>
        </div>
        <div className={styles.hr} />
        <div className={styles.meta}>
          <div>
            <b>Joined:</b> {timeSince(user.date_joined)} ago
          </div>
          <div>
            <b>Decks:</b> {user.deckCount}
          </div>
          <div>
            <b>Comments:</b> {user.commentCount}
          </div>
          <div>
            <b>Followers:</b> {user.followerCount}
          </div>
          <div>
            <b>Loyalty:</b> {user.points}
          </div>
        </div>
        <div className={`${styles.hr} ${styles.noShowOnDesktop}`} />
      </div>
      <div className={styles.bio}>
        {user.profile.bio === emptyTextArea ? (
          <div className={styles.noBio}>{user.username} has no bio</div>
        ) : (
          <Editor readOnly value={user.profile.bio} />
        )}
      </div>
    </div>
  )
}

export default UserInfoAndBioCard
